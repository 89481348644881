import React from "react";
import { useInput } from "./../utils/forms";
import { PageLoader } from "../themeComponents/Loader";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import { Typography, Divider, Box, Grid } from "@material-ui/core";
import { useStyles } from "../styles/formStyles";
import theme from "../styles/theme";
import { KiteTextInput } from "@kite/react-kite";
import { InfoIcon } from "./kiteComponents/IconComponents";
import { deleteCookie } from "../utils/common";
import { SINGLESIGNON } from "../utils/types";
import { API_URLS } from "../configs/aws";
import axios from "axios";

const Signup: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [screenState, setScreenState] = React.useState(1);
  const [resendResponse, setResendResponse] = React.useState<any>(null);
  const [confirmSignUpResponse, setConfirmSignUpResponse] =
    React.useState<any>(null);
  const classes = useStyles();
  const history = useHistory();
  const { value: code, bind: bindCode } = useInput("");

  const callbackUrl = localStorage.getItem("callbackUrl") || "";
  const clientId = localStorage.getItem("clientId") || "";
  const tempFlowLogin = localStorage.getItem("tempFlowLogin") || "";
  const confirmUsername = localStorage.getItem("confirmUsername") || "";
  const confirmEmail = localStorage.getItem("confirmEmail") || "";
  const maskedEmail =
    confirmEmail &&
    confirmEmail[0] + confirmEmail.slice(1).replace(/.(?=.*@)/g, "*");

  const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    setLoading(true);

    try {
      await Auth.confirmSignUp(confirmUsername, code);
      setScreenState(2);
    } catch (error: any) {
      setConfirmSignUpResponse(error.message);
    }
    setLoading(false);
  };

  const handleResend = async () => {
    try {
      await Auth.resendSignUp(confirmUsername);
      setResendResponse("Successfully Resent Verification Code");
    } catch (error: any) {
      setResendResponse(error.message);
    }
  };

  // handle logout and return to login page
  const handleSubmitVerifiedAccount = async (
    e: React.SyntheticEvent<Element, Event>
  ) => {
    e.preventDefault();
    setLoading(true);

    try {
      await Auth.signOut({ global: true });
      deleteCookie("user");
      history.push("/login");
    } catch (error: any) {
      setConfirmSignUpResponse(error.message);
    }
    setLoading(false);
  };

  const findRequestingApplication = (url: string) => {
    switch (true) {
      case url.includes("clientportal"):
        return "clientportal";
      case url.includes("onespot"):
        return "onespot";
      case url.includes("d22x"):
        return "clientportal";
      case url.includes("d1t5cqnhbypzk"):
        return "clientportal";
      case url.includes("dw2igr6u6bjpx"):
        return "clientportal";
      default:
        return "clientportal";
    }
  };

  const requestingApplication = findRequestingApplication(callbackUrl || "");

  const redirectTo = (url: string) => {
    window.location.href = url;
  };

  const handleGetAuthCode = async () => {
    axios
      .post(API_URLS.authCodeURL || "/missing-REACT_APP_AUTH_CODE_URL", {
        username: confirmUsername,
        password: tempFlowLogin || "",
        redirectUrl: callbackUrl || "",
        clientId: clientId || "",
        env: process.env.REACT_APP_ENV || "development",
        application: requestingApplication,
      })
      .then((result: any) => {
        localStorage.removeItem("tempFlowLogin");
        setLoading(false);
        const data = result.data;
        document.cookie = `cognito=${data?.token};Expires=${data?.expires};Max-Age=${data?.maxAge}`;
        document.cookie = `user=${data?.username};Expires=${data?.expires};Max-Age=${data?.maxAge}`;
        if (data.code) {
          localStorage.setItem("code", data.code);
          redirectTo(callbackUrl + "?code=" + data.code);
        }
        setLoading(false);
        data.code && !callbackUrl && history.push("/");
      })
      .catch((error) => {
        console.log("error: ", error);
        setLoading(false);
      });
  };

  // handle logout, sign in with new account and go to dashboard
  async function handleLogin() {
    setLoading(true);
    await Auth.signOut({ global: true });
    deleteCookie("user");
    try {
      await Auth.signIn(
        confirmUsername,
        localStorage.getItem("tempFlowLogin") || ""
      );
      handleGetAuthCode();
    } catch (error: any) {
      setConfirmSignUpResponse(error.message);
      setLoading(false);
    }
  }

  return (
    <div className={classes.root}>
      {loading && <PageLoader />}
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        onSubmit={handleSubmit}
        className={classes.form}
      >
        <Typography className={classes.cardAccentText}>
          {SINGLESIGNON.accent}
        </Typography>
        {screenState === 1 && (
          <>
            <Typography className={classes.cardText} variant="h1">
              {SINGLESIGNON.verifyYourAccount}
            </Typography>
            <Typography className={classes.cardSubText} variant="subtitle1">
              {SINGLESIGNON.confirmSubText}{" "}
              <span className={classes.maskedEmail}>{maskedEmail}</span>
            </Typography>
            <KiteTextInput
              className={classes.input}
              errorMessage=""
              id=""
              label="Verification Code"
              name=""
              {...bindCode}
            />
            {resendResponse && (
              <Grid container direction="row" alignItems="center">
                <Grid
                  item
                  style={{
                    width: "20px",
                    marginRight: "5px",
                    marginBottom: theme.spacing(2),
                  }}
                >
                  <InfoIcon
                    fill="#0073D1"
                    size="10px"
                    icon="ki-info-circle-f"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    className={classes.successMessage}
                    style={{ marginBottom: theme.spacing(3) }}
                  >
                    {resendResponse}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {confirmSignUpResponse && (
              <Grid container direction="row" alignItems="center">
                <Grid item style={{ width: "17px", marginRight: "5px" }}>
                  <InfoIcon
                    fill="#D6312B"
                    size="10px"
                    icon="ki-caution-circle-f"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    className={classes.errorMessage}
                  >
                    {confirmSignUpResponse}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              sx={{ paddingBottom: theme.spacing(5) }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={loading}
                className={classes.button + " " + classes.smallWidthButton}
              >
                <Typography className={classes.buttonSubText}>
                  {SINGLESIGNON.verify}
                </Typography>
              </Button>
              <Typography
                className={classes.secondaryButtonContent}
                variant="body2"
                onClick={handleResend}
              >
                {" "}
                {SINGLESIGNON.resendCode}{" "}
              </Typography>
            </Box>
          </>
        )}
        {screenState === 2 && (
          <>
            <Box>
              <Typography className={classes.cardText} variant="h1">
                {SINGLESIGNON.accountVerifiedSuccess}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.cardSubText} variant="subtitle1">
                {SINGLESIGNON.accountVerifiedSuccessSubText}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              sx={{ paddingBottom: theme.spacing(5) }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={loading}
                className={classes.button + " " + classes.medWidthButton}
                onClick={
                  !callbackUrl ? handleSubmitVerifiedAccount : handleLogin
                }
              >
                <Typography variant="subtitle1">
                  {!callbackUrl
                    ? SINGLESIGNON.continueToLogin
                    : SINGLESIGNON.continueTo +
                      requestingApplication.charAt(0).toUpperCase() +
                      requestingApplication.slice(1)}
                </Typography>
              </Button>
            </Box>
          </>
        )}
        <Divider className={classes.divider} />
        <Typography>
          <span className={classes.linkSpan}>{SINGLESIGNON.needHelp} </span>
          <Link to="/contact-us" className={classes.link}>
            {SINGLESIGNON.contactUs}
          </Link>
        </Typography>
      </form>
    </div>
  );
};

export default Signup;
