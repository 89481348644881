import { Auth } from "aws-amplify";

export function getCookie(cname: string) {
    const name = cname + "=";
    const ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
export function deleteCookie(name: string) {
    if (getCookie(name)) {
        document.cookie = name + '=; Max-Age=-99999999;';
    }
}

export const isUserAuthenticated = async () => {

    if (!getCookie('user')) {
        return false;
    }

    try {
        try {
            const user = await Auth.currentAuthenticatedUser();
            // Make API Call to check if tokens are still active.
            await Auth.userAttributes(user);
            return true;
        } catch (error: any) {
            // If we get an error about revoked token or invalid token, return false
            if (error.message?.includes('Token is invalid') ||
                error.message?.includes('Access Token has been revoked') ||
                error.message?.includes('Invalid session')) {
                return false;
            }
            throw error;
        }
    } catch (error: any) {
        return false;
    }
};


export const validatePassword = (password: string) => {
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);
    const hasMinLength = password.length >= 8;

    return hasLowercase && hasUppercase && hasNumber && hasSpecialChar && hasMinLength;
};
