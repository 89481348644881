import React from "react";
import { Avatar, Typography } from "@material-ui/core";
import { useStyles } from "../styles/formStyles";
import { SupportedServices } from "../utils/types";


const HeaderServices = () => {
  const classes = useStyles();
  return (
    <div className={classes.servicesContainer}>
      {SupportedServices.map((service) => (
        <div className={classes.servicesItem} key={service.name}>
          <Avatar
            style={{
              backgroundColor: service.bg,
              marginRight: "4px",
              width: "24px",
              height: "24px",
            }}
          >
            <Typography
              component="p"
              style={{
                letterSpacing: "1px",
                fontSize: "10px",
                marginLeft: "1px",
                marginBottom: "0px",
              }}
            >
              {service.shortName}
            </Typography>
          </Avatar>
          <Typography className={classes.serviceText} variant="subtitle1">
            {service.name}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default HeaderServices;
